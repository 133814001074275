.contact-submit1{
    color: #ffffff;
	cursor: pointer;
	display: inline-block;
	line-height: 35px;
	background: #8b373a !important;
	padding: 0px 20px;
	vertical-align: middle;
	font-size: 18px;
	min-width: 80px;
	min-height: 35px;
	font-family: Segoe UI;
	border: 1px solid transparent;
	margin: 5px;
    font-weight: bold;
}
.contact-submit2{
    color: #ffffff;
	cursor: pointer;
	display: inline-block;
	line-height: 35px;
    background: #917044 !important;
	padding: 0px 20px;
	vertical-align: middle;
	font-size: 18px;
	min-width: 80px;
	min-height: 35px;
	font-family: Segoe UI;
	border: 1px solid transparent;
	margin: 5px;
    font-weight: bold;
}

.ch{
	color: black;
	background-color: #faf5ea;
}

.input {
	height: 28px;

	padding: 0 3px !important;
	box-shadow: none;
	background: #f8f8f8;
	border: 1px solid #b7b7b7;
	max-width: 400px;
}
textarea.input-height {
    max-width: 27vw;
    resize: block;
    width: 90%;
    max-width: 90%;
    max-height: 30%;
}
input.input {
	max-width: 27vw;
    resize: block;
    width: 90%;
    max-width: 90%;
    max-height: 30%;
}