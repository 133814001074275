.aboutus-body {
	/* color: red; */
	/* background: url(/src/assests/Images/footerbg.png); */
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: -8px;
	margin-left: -24px;
}
.s {
	color: red;
}

.abh {
	color: black;
	background-color: #faf5ea;
}
.title2{
	font-size: 16px;
	color: #7bc236;
	font-weight: bold;
}