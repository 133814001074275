.astric {
	color: red;
}
.inputs {
	height: 28px;

	padding: 0 3px !important;
	box-shadow: none;
	background: #f8f8f8;
	/* border: 1px solid #b7b7b7; */
	max-width: 400px;
}

.submit {
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	line-height: 35px;
	background: #000;
	padding: 0px 20px;
	vertical-align: middle;
	font-size: 18px;
	min-width: 80px;
	min-height: 35px;
	font-family: Segoe UI;
	border: 1px solid transparent;
	margin: 5px;
}
/* .PhoneInput{
	display: none;
} */

.react-tel-input .form-control {
	position: relative;
	font-size: 14px;
	letter-spacing: 0.01rem;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-left: 48px;
	margin-left: 0;
	background: #f8f8f8;
	border: 1px solid #b7b7b7;
	max-width: 400px;
	border-radius: 5px;
	line-height: 25px;
	height: 35px;
	width: 400px;
	outline: none;
}

.form-select {
	display: block;

	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	-moz-padding-start: calc(0.75rem - 3px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;

	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.react-date-picker__wrapper {
	display: flex;
	flex-grow: 1;

	width: 400px !important;
	border: 1px solid #b7b7b7;
}
.reh {
	color: black;
	background-color: #faf5ea;
}

.inputs[type="date"]::-webkit-calendar-picker-indicator {
	/* //display: none;
	//-webkit-appearance: none; */

	padding: 30px;
	margin: 0px;
	/* margin-top: 10px; */
}
