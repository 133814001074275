.footer-body {
	padding-top: 25px;
		background: url(/src/assests/Images/footerbg.png) repeat-x #323232;
	}
	.partners {
		background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABVAAD/7gAmQWRvYmUAZMAAAAABAwAVBAMGCg0AAAGrAAABzAAAAgQAAAIv/9sAhAACAQEBAQECAQECAwIBAgMDAgICAgMDAwMDAwMDBQMEBAQEAwUFBQYGBgUFBwcICAcHCgoKCgoMDAwMDAwMDAwMAQICAgQDBAcFBQcKCAcICgwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wgARCAAvAAEDAREAAhEBAxEB/8QAfgABAQEAAAAAAAAAAAAAAAAABQQIAQEAAAAAAAAAAAAAAAAAAAAAEAEBAQEBAAAAAAAAAAAAAAAAEyARAhEBAAAAAAAAAAAAAAAAAAAAIBIBAAAAAAAAAAAAAAAAAAAAIBMAAQQBBQAAAAAAAAAAAAAAACABEWHwUXGRsfH/2gAMAwEAAhEDEQAAAd8B4WEBRAf/2gAIAQEAAQUCoqsss76f/9oACAECAAEFAtf/2gAIAQMAAQUC1//aAAgBAgIGPwJf/9oACAEDAgY/Al//2gAIAQEBBj8CX//aAAgBAQMBPyGBAkR1JFbn/9oACAECAwE/IVf/2gAIAQMDAT8hV//aAAwDAQACEQMRAAAQgAA//9oACAEBAwE/ELC4s7MDMbPLnlOf/9oACAECAwE/EFf/2gAIAQMDAT8QV//Z)
		repeat-x;
	}
	
	.partner-list {
		padding-bottom: 20px;
		text-align: center;
		padding-left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	ul.partner-list li {
		display: inline-block;
		padding: 3px 16px;
	}
	ul.partner-list li h5{
		margin-bottom: 0;
	}
	.footer-subhedding {
		color: #e4984e;
		font-size: 18px;
		margin-bottom: 5px;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: bold;
		padding-left: 10px;
		position: relative;
		left: 31px;
	}
	.footerinfo {
		display: block;
		color: aliceblue;
		margin-top: 12px;
		font-size: 12px;
	}
	.footerinfo1{
		display: block;
		color: aliceblue;
		margin-top: -5px;
		font-size: 12px;
	}
	
	.second-footer {
		background: #000;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(-1 * var(--bs-gutter-y));
		text-align: center;
		font-weight: 600;
	
		color: #b1a079;
		font-size: 9px;
		line-height: 14px;
		text-decoration: none;
	
		padding-top: 12px;
		margin-top: 30px;
	}
	.copyright {
		text-align: center;
		font-size: 9px;
		/* padding-left: 240px; */
		font-weight: normal;
	}
	
	.footer-link2 {
		font-weight: 600;
	
		color: #b1a079;
		font-size: 11px;
		line-height: 14px;
		text-decoration: none;
		position: relative;
		padding: 0 2px;
	}
	
	.footer-link2:hover {
		color: white;
	}
	
	@media (max-width: 991px) {
		.partner-list{
			display: block;
			padding-bottom: 0px;
			background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABVAAD/7gAmQWRvYmUAZMAAAAABAwAVBAMGCg0AAAGrAAABzAAAAgQAAAIv/9sAhAACAQEBAQECAQECAwIBAgMDAgICAgMDAwMDAwMDBQMEBAQEAwUFBQYGBgUFBwcICAcHCgoKCgoMDAwMDAwMDAwMAQICAgQDBAcFBQcKCAcICgwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wgARCAAvAAEDAREAAhEBAxEB/8QAfgABAQEAAAAAAAAAAAAAAAAABQQIAQEAAAAAAAAAAAAAAAAAAAAAEAEBAQEBAAAAAAAAAAAAAAAAEyARAhEBAAAAAAAAAAAAAAAAAAAAIBIBAAAAAAAAAAAAAAAAAAAAIBMAAQQBBQAAAAAAAAAAAAAAACABEWHwUXGRsfH/2gAMAwEAAhEDEQAAAd8B4WEBRAf/2gAIAQEAAQUCoqsss76f/9oACAECAAEFAtf/2gAIAQMAAQUC1//aAAgBAgIGPwJf/9oACAEDAgY/Al//2gAIAQEBBj8CX//aAAgBAQMBPyGBAkR1JFbn/9oACAECAwE/IVf/2gAIAQMDAT8hV//aAAwDAQACEQMRAAAQgAA//9oACAEBAwE/ELC4s7MDMbPLnlOf/9oACAECAwE/EFf/2gAIAQMDAT8QV//Z);
		}
		.secon{
			display: none;
		}

	
	}
	

	
	/* @media  (min-width: 768px){
	
	.partners {
	background: url(/src/assests/Images/partners_bg.jpeg) repeat;
	line-height: 20px;
	
	}
	
	
	} */
	
	