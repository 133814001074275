.blogbh{
    background-image: url('../../assests/Images/blog\ background.jpeg');
}

.buper-body{

	padding-left: 100px;
	margin-top: 20px;
    
    
}

.blog-head{
    background-image: url('../../assests/Images/blog\ background.jpeg');

}


.blog-link{
    font-weight: normal;
    font-size: 140%;
    color: #1b0431;
    margin: 0;
    padding: 0;
    font-family:  Georgia, 'Times New Roman', Times, serif, serif;
    text-decoration: none;
   
}
.sub-header{
    float: right;
}
.blogactivity-body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: justify;
    padding-top: 12px;
    color: #29303b;
    font-family:  Georgia, 'Times New Roman', Times, serif, serif;

}

.subblog-link{
    color: #635e54;
text-decoration-style: double;
font-family:  Georgia, 'Times New Roman', Times, serif, serif;


}