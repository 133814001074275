

  .home-linkay{
    color: #d57a27;
	font-size: 12px;
	font-weight: bold;

	cursor: pointer;
	text-decoration: none;

  }