.Ah {
	color: black;
	background-color: #faf5ea;
}
.title11 {
	

	font-size: 16px;
	color: #7bc236;
	font-weight: bold;
}
.bird-watching {
	
	max-width: 100%;

}
.activity-list2{
	font-weight: normal;
}
.activity-body {
	color: #635e54;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	text-align: justify;
	padding-top: 12px;
}
.activity-list1 {
	font-weight: normal;
}

.title2 {
	font-size: 16px;
	color: #7bc236;
	font-weight: bold;
}
.cycling {
	max-width: 100%;
}
.subtitle1 {
	font-weight: bold;
	font-size: 16px;
	color: black;
	padding-top: 10px;
}
.subtitle2 {
	font-weight: bold;
	font-size: 13px;
	color: #635e54;
	margin-top: -10px;
}

.title4 {
	padding-top: 20px;
	font-size: 20px;
	font-weight: bold;
}
.title4-body {
	color: #635e54;
}
.home-linkac{
	color: #d57a27;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    position: relative;
}


	
