.pht1 {
	/* margin-left: -37px; */
	max-width: 100%;
}
.bird-subtitler{
	font-size: 16px;
	color: #7bc236;
	font-weight: bold;
	
}


