.bird-subtitle1 {
	
	font-size: 16px;
	color: #7bc236;
	font-weight: bold;
	
}
.birdwelcome-body {
	color: #635e54;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	text-align: justify;

	margin-top: -40px;
}

.resortbird-watching {
	padding-top: 31px;
	max-width: 100%;
	

	
}
.birdResortactivity-body {
	color: #635e54;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	text-align: justify;

	padding-top: 20px;
}
.birdactivity-list1 {
	font-weight: normal;

}

.birdrest-subheading {


    font-family: Arial, Helvetica, sans-serif;

}

 
.resortbird-subtitle2 {
	
	color: #635e54;
	font-weight: bold;
	font-size: 13px;
	padding-top: 15px;
}

.home-linkbc{
	color: #d57a27;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
	position: relative;
	right: 10px;
}


