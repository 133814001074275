.App {
	text-align: center;
}


.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.body-container {
	max-width: 1035px;
	margin: auto;
}
.secondfooter {
	padding-top: -10px;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	text-align: justify;
	color: #6e5941;
	line-height: 18px;
	background: url(/src/assests/Images/pagebg.png);
}

@media (max-width: 375px) {
	.body-container {
		max-width: 800px;
	}
}
