.table-subhead {
	color: #7bc236;
	font-weight: bold;
	font-size: 18px;
}
.inside-table th {
	background-color: #ffffff;
	color: #666666;
	font-size: 12px;
    padding: 0px 6px 3px 20px;
    text-align: justify;
}
.inside-table td {
	background-color: #ffffff;
	color: #666666;
	font-size: 12px;
	padding: 0px 6px 3px 20px;
    text-align: justify;
}

.weddingTab-data {
	color: #666666;
	font-family: Arial, Helvetica, sans-serif;
}
.weeding-footer {
	/* font-weight: 550; */
	font-size: 12px;
	font-family: 'Lato', sans-serif;
    
}

.wedding-top {
	color: #585042;
	text-align: right;
	font-size: 12px;
	margin-top: 10px;
}

.wed-tab {
}

hr{
    display: block;
    unicode-bidi: isolate;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .1;
}

.wdh {
	color: black;
	background-color: #faf5ea;
}

