.timthumb1{
    border: 8px solid #FFFFFF;
    box-shadow: 0 0 5px #9c9c9c;
    float: left;
    margin-right: 10px
}
.gh {
	color: black;
	background-color: #faf5ea;
}
