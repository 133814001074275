.navigation-bar {
	/* background: url("/src/assests/Images/header_bg.png"); */
	/* margin-top: 20px; */
    padding-top: 1px;

    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 13px;
   
    max-width: 100%;
    position: relative;
}

.navigation-bar .extra-links{
	position: absolute;
	right: 35px;
    top: 11px;
    z-index: 10;
}

.navbar>.container{
	align-items: end;
}
.navbar-dark .navbar-toggler-icon {
    background-color: #8b6a35;
	border-radius: 5px;

    /* color: red; */
}
.nav-logo {
	width: 250px;
}
.navlink-menu {
	color: #875a29;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	padding-right: 100px;
	padding-top: 50px;
	font-weight: bold;
}
/* .facebook-icon{
    padding-bottom: 20px;
} */
.secondNav {
	padding-top: 60px;
	padding-right: 20px;
}
.nav-menu :hover {
	color: black;
}
/* @media (max-width: 375px) {
	.nav-logo {
		max-width: 200px;
	}

}

@media (max-width: 820px) {
	.navigation-bar {
		max-width: 704px;
	
	}
}
@media (max-width: 768px) {
	.navigation-bar {
		max-width: 704px;
		
	}
}

@media (max-width: 414px) {
	.nav-logo {
		max-width: 200px;
	}
}

@media (max-width: 360px) {
	.nav-logo {
		max-width: 150px;
	}
}
@media (max-width: 280px) {
	.nav-logo {
		max-width: 100px;
	}
} */
